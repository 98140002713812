.card {
  border-radius: 8px;
  box-shadow: -3px -3px 3px 0 rgba(38, 230, 247, 0.6),
    3px 3px 3px 0 rgba(247, 89, 228, 0.6), 0 0 6px 2px rgba(255, 231, 89, 0.6),
    0 35px 25px -15px rgba(0, 0, 0, 0.5) !important;
  position: relative;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  width: 200px;
}

.card:before,
.card:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-image: linear-gradient(
    115deg,
    transparent 0%,
    rgb(0, 231, 255) 30%,
    rgb(255, 0, 231) 70%,
    transparent 100%
  ) !important;
  background-position: 0% 0%;
  background-repeat: no-repeat;
  background-size: 300% 300%;
  mix-blend-mode: color-dodge;
  opacity: 0.2;
  z-index: 1;
}

.card:after {
  background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/13471/sparkles.gif') !important;
  background-position: center;
  background-size: 180%;
  mix-blend-mode: color-dodge;
  opacity: 1;
  z-index: 2;
}

.card.active:before {
  opacity: 1;
  animation: none;
  transition: none;
  background-image: linear-gradient(
    115deg,
    transparent 0%,
    transparent 25%,
    rgba(0, 231, 255, 0.7) 45%,
    rgba(255, 0, 231, 0.7) 55%,
    transparent 70%,
    transparent 100%
  ) !important;
}

@keyframes holoSparkle {
  0% {
    opacity: 0;
  }
  12% {
    opacity: 1;
  }
  70% {
    opacity: 0.5;
  }
  95% {
    opacity: 0.2;
  }
}

@keyframes holoGradient {
  3% {
    opacity: 0;
  }
  5% {
    background-position: 0% 0%;
  }
  7% {
    opacity: 0.5;
  }
  9% {
    background-position: 100% 100%;
  }
  11% {
    opacity: 0;
  }
  50% {
    opacity: 0;
    background-position: 100% 100%;
  }
  55% {
    opacity: 0.3;
  }
  70% {
    opacity: 0;
    background-position: 0% 0%;
  }
}

.card-animation .row {
  gap: 20px;
  margin: 0;
}
.card-animation .row .col-md-4 {
  padding: 0;
  max-width: 32%;
}
.card-animation .card {
  background-color: #27272a;
  position: relative;
  overflow: hidden;
  vertical-align: middle;
  border-radius: 12px;
  transform-style: preserve-3d;
}

.card-animation .card .card-content {
  color: #fff;
  padding: 10px;
}
.card-animation .card .card-content .flex-content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.card-animation .card .card-content .flex-content a {
  color: #a1a1aa;
  text-decoration: none;
}
.card-animation .card .card-content .flex-content .title {
  font-size: 20px;
  font-weight: 700;
}
.card-animation .card .card-content .flex-content .red-border {
  border: 2px solid #36d399;
  color: #36d399;
  padding: 4px 6px;
  line-height: 1;
  border-radius: 6px;
}
.card-animation .card .card-content ul.text-xs {
  margin: 0;
  text-align: left;
  padding: 0;
  list-style: none;
}
.card-animation .card .card-content ul.text-xs li {
  font-size: 14px;
  color: #a1a1aa;
}

.card {
  position: relative;
  cursor: pointer;
  transform-style: preserve-3d;
  transition: transform 0.8s;
}
.card.is-flipped {
  transform: rotateY(360deg);
}

.font-weight-700 {
  font-weight: 700;
}
.levitating-container {
  position: relative;
}

.levitating-reflection-image {
  animation: levitate 3s infinite ease-in-out;
  display: block;
  transform-origin: center;
  -webkit-box-reflect: below 10px linear-gradient(transparent, rgba(0, 0, 0, 0.2)) !important; /* Reflection effect */
}

@keyframes levitate {
  0% {
    transform: translateY(0) scale(1);
  }
  50% {
    transform: translateY(-15px) scale(1.05);
  }
  100% {
    transform: translateY(0) scale(1);
  }
}

.swiper-3d .swiper-slide,
.swiper-3d .swiper-cube-shadow {
  background: transparent !important;
  width: 250px !important;
}

.swiper-3d .swiper-slide-shadow-right {
  background-image: none !important;
}

.swiper-3d .swiper-slide-shadow-left {
  background-image: none !important;
  padding-left: 24px !important;
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  display: none !important;
}
