.back {
  filter: blur(8px);
}
.loader {
  justify-content: center;
  display: flex;
  position: relative;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 99;
  align-items: center;
  margin: 0 auto;
  right: 0;
  //   width: 100vw;
  height: 100%;
  // background: #404040;
  color: #3b5de7;
  // color: aliceblue;
  //   filter: blur(8px);
}

.loader1 {
  justify-content: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 99;
  align-items: center;
  // margin: 0 auto;
  margin-top: 170px;
  right: 0;
  //   width: 100vw;
  height: 80%;
  // background: #404040;
  background: rgba(255, 255, 255, 0.7);
  color: #3b5de7;
  // color: aliceblue;
  //   filter: blur(8px);
}
