/* The slider */
.slider-fun-mode {
  cursor: pointer;
  bottom: 0;
  background-color: rgb(182, 182, 182);
  transition: 0.4s;
  border-radius: 10px;
}

.slider-fun-mode:before {
  position: absolute;
  content: '';
  height: 1.4em;
  width: 1.4em;
  border-radius: 8px;
  left: 0.3em;
  bottom: 0.3em;
  transform: rotate(270deg);
  background-color: rgb(255, 255, 255);
  transition: 0.4s;
}

.switch-fun-mode input:checked + .slider-fun-mode {
  background-color: #21cc4c;
}

.switch-fun-mode input:focus + .slider-fun-mode {
  box-shadow: 0 0 1px #2196f3;
}

.switch-fun-mode input:checked + .slider-fun-mode:before {
  transform: translateX(1.5em);
}

.container-mode .embed[data-v-38025f77] {
  border: 0px solid #b8b2db;
}

.box-shadow-icon {
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.confirmation-bg {
  background: #2196f3;
}

.circle {
  width: 50px;
  height: 50px;
  background-color: #aafac8;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: breathe 2s ease-in-out infinite;
}

.circle-failed {
  width: 50px;
  height: 50px;
  background-color: #ff6347;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: breathe 2s ease-in-out infinite;
}

@keyframes breathe {
  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }
}

.checkmark {
  width: 50px;
  height: 50px;
}

.ticket {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: #ffffff;
  background-origin: border-box;
  -webkit-mask: radial-gradient(circle 30px at right, #0000 95%, #000) right,
    radial-gradient(circle 30px at left, #0000 95%, #000) left;
  -webkit-mask-size: 51% 100%;
  -webkit-mask-repeat: no-repeat;
}

.collectible-item {
  overflow: scroll;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: #ffffff;
  background-origin: border-box;
}

.text-grey {
  color: #666666;
}

.scrollable-container {
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 16px;
  border: 1px solid #ddd;
  background-color: #f8f9fa;
  width: 100%;
  border-radius: 8px;
}

.scrollable-container::-webkit-scrollbar {
  width: 8px;
}

.scrollable-container::-webkit-scrollbar-thumb {
  background-color: #ccc; /* Customize scrollbar color */
  border-radius: 4px;
}

.scrollable-container::-webkit-scrollbar-thumb:hover {
  background-color: #aaa; /* Darker color on hover */
}

.scrollable-container > .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
