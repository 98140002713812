.avatar_image {
    width: 40px;
    height: 40px;
    margin-right: 12px;
    border-radius: 50%;
    overflow: hidden;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
}

img.avatar_image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-bottom: 16px;

    @media (max-width: 780px) {
        width: 60px;
        height: 60px;
    }
}

.text-black {
    color: black;
}

.font-size-24 {
    font-size: 24px;

    @media (max-width: 780px) {
        font-size: 20px;
    }
}

.font-weight-medium {
    font-weight: 500;
}

.btn-auth {
    width: 100%;
    background: black;
    color: white;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 780px) {
        height: 48px;
        font-size: 14px;
    }
}

.btn-auth .spinner {
    display: flex;
    justify-content: center;
    align-items: center;
}

.font-weight-bold {
    font-weight: 700;
}

@media (max-width: 780px) {
    .btn-auth {
        font-size: 16px;
        padding: 10px;
        height: 50px;
    }

    .font-size-24 {
        font-size: 20px;
    }

    .font-weight-medium {
        font-size: 18px;
    }

    img.avatar_image {
        width: 60px;
        height: 60px;
    }

    .spinner {
        font-size: 20px;
    }

    .row {
        flex-direction: column;
    }
}

@media (min-width: 780px) {

    .btn-auth {
        font-size: 18px;
        height: 56px;
    }
}

.product-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 24px;
    height: 79vh;
    flex-direction: row;
    margin-top: 24px;
}


.product-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f5f5f7;
    border-radius: 16px;
    min-height: 50%;
    max-height: 70%;
}

.product-image img {
    width: 200px;
    height: 200px;
    object-fit: contain;
}

.product-details {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    width: 100%;
}

.author-info {
    display: flex;
    align-items: center;
}

.author-info .avatar_image {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    border-radius: 50%;
}

.payment-info {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
}

.wallet-connection {
    margin-top: 48px;
    width: 100%;
}

@media (max-width: 779px) {
    .product-container {
        flex-direction: column;
    }

    .product-details {
        padding-left: 0;
    }

    .wallet-connection button {
        width: 100%;
    }

    .product-image {
        max-width: 100% !important;
        width: 100%;
        min-height: 50%;
        max-height: 70%
    }
}

@media (max-height: 540px) {
    .product-image {
        max-width: 100% !important;
        width: 100%;
        height: 100%;
        min-height: 80%;
    }

}